class ObjectActionComponent extends WebPageComponentClass {
    constructor(element) {
        super(element);

        this.client = new HttpClient();
        this.updatingCounter = new HtmlClassCounter(this.element, "Updating");

        this.determineElements();
        this.attachRefreshHandler();
    }

    attachRefreshHandler() {
        const query = new DomQuery(this.element);
        const item = query.getDescendant(WithClass("Refresh"));

        if (item !== null)
            item.addEventListener(
                "click",
                (event) => { this.refresh(); }
            );
    }

    determineElements() {
        const query = new DomQuery(this.element);

        this.toolbar = new Toolbar(query.getDescendant(WithClass("Toolbar")));
        this.toolbar.parentComponent = this;
    }

    fireDataChanged() {
        distributeEvent(new DataChangedEvent(this));
    }

    getForm() {
        return this.content;
    }

    handleEvent(event) {
        if (event instanceof DataChangedEvent && this.refreshForSiblings)
            this.refresh();
    }

    handleHttpCommandResponse(xmlResponse) {
        const contentType = xmlResponse.getResponseHeader("Content-Type");

        if (contentType.slice(0, 15) == "application/xml") {

            const response = xmlResponse.responseXML;
            const query = new DomQuery(response.documentElement);

            const updateScopeElement = query.getChild(WithTagName("UpdateScope"));
            let updateScope = RenderScope.Complete;

            if (updateScopeElement !== null)
                updateScope = RenderScope.fromText(updateScopeElement.getAttribute("Value"));

            this.sendRefreshRequest(updateScope);
        }

        this.updatingCounter.decrease();
    }

    refresh() {
        this.sendCommandRequest("<Refresh/>");
    }

    refreshWithScope(xmlResponse, scope) {
        let dummyElement = document.createElement("div");

        if (scope === RenderScope.Toolbar) {
            dummyElement.innerHTML = xmlResponse.responseText;

            const newToolbar = dummyElement.firstChild;
            this.toolbar.replace(newToolbar, true);

            this.attachRefreshHandler();
        }
        else {
            dummyElement.innerHTML = xmlResponse.responseText;
            dummyElement = dummyElement.firstChild;

            const newToolbar = dummyElement.childNodes[0];
            const newContent = dummyElement.childNodes[1];

            this.content = newContent;

            this.toolbar.replace(newToolbar, false);
            this.attachRefreshHandler();
        }

        this.updatingCounter.decrease();
    }

    refreshFromResponse(xmlResponse) {
        this.refreshWithScope(xmlResponse, RenderScope.Complete);
    }

    sendActionRequest(action, form, callback) {
        const xmlRequest = newXmlRequest();

        let formData;

        if (form !== null)
            formData = new FormData(form);
        else
            formData = new FormData();

        formData.append("Action", action);
        this.updatingCounter.increase();

        xmlRequest.open("POST", this.uri, true);
        xmlRequest.setRequestHeader("Accept", "text/html;q=1, */*;q=0.9")
        xmlRequest.onreadystatechange = () => {
            if (xmlRequest.readyState == 4) {
                if (xmlRequest.status === 200) {
                    const contentType = xmlRequest.getResponseHeader("Content-Type");

                    if (contentType.slice(0, 15) == "application/xml") {
                        const response = xmlRequest.responseXML;

                        if (response.documentElement.hasAttribute("Location")) {
                            const response = xmlRequest.responseXML;
                            const type = response.documentElement.getAttribute("Type");
                            const redirect = response.documentElement.getAttribute("Location");

                            if (type == "Download") {
                                window.open(redirect);
                                this.updatingCounter.decrease();
                                this.sendCommandRequest("<Action Name=\"\"/>");;
                            }
                            else if (type == "Redirect") {
                                application.pageHandler.load(redirect);
                            }
                        }
                        else {
                            this.handleHttpCommandResponse(xmlRequest);
                            this.fireDataChanged();

                            application.toastBox.addMessage(new ToastMessage("Action submitted successfully", "Success"));
                        }
                    }
                }
                else if (xmlRequest.status === 400) {
                    this.toolbar.state = ToolbarState.Warning;
                    this.refreshFromResponse(xmlRequest);
                    this.fireDataChanged();
                }
                else if (xmlRequest.status === 409 || xmlRequest.status === 500) {
                    const division = document.createElement("div");
                    division.innerHTML = xmlRequest.responseText;

                    const error = new DomQuery(division).getDescendant(WithClass("Error"));
                    application.toastBox.addMessage(new ToastMessage(error.innerHTML, "Error"));

                    this.updatingCounter.decrease();
                }

                callback();
            }
        };

        xmlRequest.send(formData);
    }

    sendCommandRequest(command) {
        this.updatingCounter.increase();

        this.client.sendXmlRequest(
            this.uri,
            "<Commands>" + command + "</Commands>",
            (xmlResponse) => { this.handleHttpCommandResponse(xmlResponse); }
        );
    }

    sendRefreshRequest(scope) {
        this.updatingCounter.increase();
        this.client.sendXmlRequest(
            this.uri,
            "<Render Scope=\"" + RenderScope.toText(scope) + "\"/>",
            (xmlResponse) => { this.refreshWithScope(xmlResponse, scope); }
        );
    }

    toggleAction(action) {
        this.sendCommandRequest("<Action Name=\"" + action + "\"/>");
    }

    get refreshForSiblings() {
        return this.element.dataset.RefreshForSiblings === "true";
    }

    get uri() {
        return this.element.dataset.Uri;
    }

    get content() {
        return this.element.childNodes[1];
    }

    set content(value) {
        interactivityRegistration.detach(this.content);
        this.element.replaceChild(value, this.content);
        interactivityRegistration.attach(this.content);
    }
}

interactivityRegistration.register("ObjectActionComponent", function(element) { return new ObjectActionComponent(element); });
